<template>
  <div id="app1">
    <div  @click="goPrev">
       <div class="header P_2">
        <img src="../assets/icon30.png" alt="">
        <span>生态链健康指数</span>
      </div>
    </div>
    <div class="box">
      <img src="../assets/shengtailianjian kanbanner@2x.png" alt="">
      <div class="abso" style="padding:1rem 1.5rem;width:100%">
        <div class="txt1">生态链健康指数</div>
        <div class="txt2">{{Number(scoreItem.ecological_chain_health_score).toFixed(1) }} <span>分</span></div>
        <div class="model1">
            <div class="title">指数解读</div>
            <div class="subTitle">生态链健康指数是基于以下纬度生成</div>
            <div class="btns">
              <div class="btn2" @click="goDetail(5,'营商环境压力')" style="background-color:#EDE5FF">营商环境压力</div>
              <div class="btn2" @click="goDetail(5,'关联企业风险')" style="background-color:#EDE4FF" >关联企业风险</div>
            </div>
            <div>
              <div class="title2">本次信用测评为：<span style="color:#4222D6">{{content2}}</span> </div>
              <div class="txt">{{content4}}</div>
            </div>
            <div class="title2" style="padding-bottom:1.3rem;">生态链健康指数发展趋势为：<span style="color:#49C254">平稳</span> </div>
        </div>
        <!-- <div class="model2">
          <div>
            <div class="title">生态链健康指数</div>
            <div class="txt"> {{content2}}</div>
          </div>
          <div  id="main" style="width: 25rem; height:10rem;"></div>
        </div> -->
        <div class="model3">
          <div>
            <div class="title">评价说明</div>
            <div class="companyTitle title" style="margin-top:0;padding:1rem 0">
              <div class="dot" style="background-color:#4222D5"></div>
              <div style="margin-left:0.5rem; font-weight: 600;">{{content3}}</div> 
            </div>
            <div class="txt"> {{content1}}</div>
          </div>
        </div>
        <div style="height:5rem"></div>
      </div>
    </div>
    <div class="fixBtn"> 
      <div class="btn" @click="goPrev" style="width:100%;">返回 力度分</div>  
      <!-- <div class="btn MR_2" @click="goPrev">上一页</div>  
       <div class="btn" style="border-top-right-radius: 5rem;border-bottom-right-radius: 5rem;">详细信息</div>   -->
    </div>
  </div>
</template>
<script>
 import * as echarts from 'echarts';
import {mapState,} from "vuex"
export default {
   mounted(){
    let {
      credit_quality_score,
      ecological_chain_health_level,
      viability_score,
      transparent_operation_score,
      ecological_chain_health_score,
      competition_score,
      operational_capability_score} = this.scoreItem
    //  let arr = [credit_quality_score,viability_score,transparent_operation_score,ecological_chain_health_score,competition_score,operational_capability_score].map(Number)
    //  this.initEacharts(arr)
     this.getContent( ecological_chain_health_level)
  },
   data(){
    return {
      content1:"", //评价
      content2:"", //能力
      content3:"", //评价标题
      content4:"",
    }
  },
  computed:{
    ...mapState({
      scoreItem:  (state) => state.scoreItem,
    }),
  },
  methods:{
    goDetail(type,content){
      this.$router.push({path: "/getLmxDesc",query:{
        type,
        content,
      }})
    },
    getContent(val){
      console.log(val)
      switch (val){
        case 'A':
            this.content1 = "该企业所处行业繁荣兴旺，且往来交易和睦共赢，关联风险极低，不容错过～"  
            this.content4 = "企业关联风险极低，生产经营内外环境优渥，为行业楷模。"  
            this.content2 = "极好"
            this.content3 = "最强王者，行业楷模。"  
            break;
        case 'B':
            this.content1 = "该企业内外合作，其关联风险很低无需担忧。"  
            this.content4 = "企业关联风险很低，与关联企业合作共赢，持续发展。"  
            this.content2 = "优秀"
            this.content3 = "璀璨钻石，赤诚相待。"  
            break;
        case 'C':
            this.content1 = "该企业所处行业平稳发展，且往来交易协调有序，关联风险很低。"  
            this.content4 = "企业关联风险应对能力良好，不断提升环境变化抗压能力，平稳发展。"  
            this.content2 = "良好"
            this.content3 = "华贵铂金，和睦共赢。"  
            break;
        case 'D':
            this.content1 = "该企业应对内外部风险的能力尚可，但仍需多多观察其所处产业动态。"  
            this.content4 = "企业信息披露真实程度不错，仍需注意经营及交易数据记录，未来可期。企业关联风险应对能力不错，仍需注意合作伙伴的交易变化因素，发展空间很大。"  
            this.content2 = "中等"
            this.content3 = "荣耀黄金，井井有条。"  
          break;
         case 'E':
            this.content1 = "该企业应对内外部风险的能力有很大的提升空间～"  
            this.content4 = "企业正在努力提升内外部风险应对的实力，来日可期。"  
            this.content2 = "较差"
            this.content3 = "坚韧黄铜，茁壮成长。"  
          break;
        default:
        }
    },
    // 雷达图
    initEacharts(arr) {
      var myChart = echarts.init(document.getElementById('main'))
      var option;
      option = {
      radar: {
        indicator: [
            { name: '信用质量评分', max: 1000,   },
            { name: '生存能力评分', max: 1000,},
            { name: '企业透明指数', max: 1000, },
            { name: '生态链健康指数', max: 1000,color:"#4222D5", },
            { name: '同业竞争评分', max: 1000 },
            { name: '经营能力评分', max: 1000, }
        ],
        center: ['55%', '45%'],
        radius: 25,
      },
      series: [{
        type: 'radar',
        data: [
          {
            value: arr,
            areaStyle: {
              color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                {
                  color: 'rgba(66,34,213, 0.1)',
                  offset: 0 
                },
                {
                  color: 'rgba(66,34,213, 0.9)',
                  offset: 1
                }
              ])
            }
          }
      ]
      }]
    };
    myChart.setOption(option); 
    },
    goPrev(){
      this.$router.push("/reportDetail")
    },
     
  }
}
</script>
<style lang="scss" scoped>
#app1{
  background:#Fff;
}
.list{
  margin-top:2rem;
  display: flex;
  justify-content:space-around;
  align-items:flex-end;
  .item{
     
    .bar{
      width: 1rem;
      // height: 2rem;
       
      border-radius: 1rem;
      margin:0 auto;
    }
    .txt{
      width:2rem;
      margin-top:0.5rem;
    }
  }
}
 .companyTitle{
  display: flex;
  align-items: center;
 }
</style>